<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Photography</h1>
              <!-- <div class="pagehead-link">
              <a href=""><img src="/html-components/images/svg/link.svg" alt=""></a>
            </div> -->
            </div>
            <p>
              Photography helps to express emotion, communicate ideas, and drive
              attention to featured content. When overused, photography becomes
              clutter that users find overwhelming.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="ohio-brand">
              <div class="content-band mt-40">
                <div class="container max-w800 gray-250 p-40">
                  <h2>The Ohio Brand</h2>
                  <p class="semibold font-red">People</p>
                  <p>
                    The preferred photographic style for Ohio materials is
                    straightforward, honest and approachable, employing natural
                    lighting and capturing real moments. Featuring everyday
                    people, the photos show subjects engaged in real, everyday
                    activities. The photographic style is friendly, unassuming,
                    inclusive and forward thinking. It should reflect and
                    challenge our preconceptions of what our region is and
                    should represent. Photos should inspire and motivate
                    residents, businesses and visitors.
                  </p>

                  <p class="semibold font-red mt-30">
                    Landscape and Environment
                  </p>
                  <p>
                    Landscape and environment photography should also be
                    straightforward, honest and approachable. The photos are
                    friendly, inviting, inclusive and forward thinking. Images
                    of our flat plains, surrounding hills, valleys and rivers,
                    as well as our architecture, should be shot with a dramatic
                    look and tone. Drama can be created through cropping and
                    juxtaposition.
                  </p>
                </div>
              </div>
            </section>

            <section id="photo-selection">
              <div class="content-band">
                <div class="container">
                  <h2>Photo Selection</h2>
                  <p>
                    To ensure chosen photography is engaging and helps to tell
                    the story of the content, consider photos that are topical,
                    cultural, and environmental, as well as:
                  </p>
                  <ul class="bullet-red">
                    <li>
                      use real people showing spontaneous and natural behavior,
                    </li>
                    <li>
                      utilize best practices around composition of subject
                      matter and use of negative space.
                    </li>
                  </ul>
                </div>

                <div class="container">
                  <h3 class="mb-10 mt-40">Topical</h3>
                  <p class="font-red mt-0">
                    Connect the image to the content
                  </p>
                  <p>
                    Topical photos show people in the environment they live and
                    work in, providing an authentic view of activity and
                    purpose. These photos should feel organic and casual with a
                    strong focus on one to to three people.
                  </p>

                  <h3 class="mb-10 mt-30">Cultural</h3>
                  <p class="font-red mt-0">
                    Showcase the spirit, diversity, and energy of Ohioans.
                  </p>
                  <p>
                    Cultural photos capture the uniqueness of the different
                    communities, organizations, and groups that make Ohio
                    diverse.
                  </p>

                  <h3 class="mb-10 mt-30">Enviromental</h3>
                  <p class="font-red mt-0">
                    Capture the beauty and uniqueness of Ohio.
                  </p>
                  <p>
                    Environmental photos showcase the variety of Ohio lifestyles
                    and surroundings.
                  </p>
                </div>
              </div>
            </section>

            <div class="divider"></div>

            <section id="photo-composition">
              <div class="content-band">
                <h2>Photo Composition</h2>
                <p>
                  Composition is how the elements of a photo are arranged.
                  Understanding the basics of composition will help guide your
                  selection choices...
                </p>
                <h3 class="mt-40">Natural and spontaneous</h3>
                <p>
                  Audiences are more likely to connect to images that appear
                  authentic and 'in the moment'. Avoid photos that seem posed
                  and unnatural.
                </p>
                <div class="card-container even-cols gap-10 max-w800">
                  <div class="photo-box">
                    <div class="good-bad">
                      <img
                        src="../../assets/images/Icon-Check.svg"
                        alt="Good Example"
                      />
                    </div>

                    <img
                      class="img-inside"
                      src="../../assets/images/photo_nat_1.jpg"
                      alt="Man writing on whiteboard"
                    />
                  </div>

                  <div class="photo-box">
                    <div class="good-bad">
                      <img
                        src="../../assets/images/Icon-Check.svg"
                        alt="Good Example"
                      />
                    </div>
                    <img
                      class="img-inside"
                      src="../../assets/images/photo_nat_2.jpg"
                      alt="Woman and Man working at a desk"
                    />
                  </div>

                  <div class="photo-box">
                    <div class="good-bad">
                      <img
                        src="../../assets/images/Icon-X.svg"
                        alt="Bad Example"
                      />
                    </div>
                    <img
                      class="img-inside"
                      src="../../assets/images/photo_nat_3.jpg"
                      alt="Staged group photo"
                    />
                  </div>
                </div>

                <h3 class="mt-60">Subject Focused and Clutter Free</h3>
                <p>
                  Select images that focus on specific details within a space or
                  person. Avoid using photos that are over-complicated or
                  cluttered.
                </p>
                <div class="card-container even-cols gap-10 mb-40 max-w800">
                  <div class="photo-box">
                    <div class="good-bad">
                      <img
                        src="../../assets/images/Icon-Check.svg"
                        alt="Good Example"
                      />
                    </div>
                    <img
                      class="img-inside"
                      src="../../assets/images/photo_sub_1.jpg"
                      alt="Woman with kitten"
                    />
                  </div>

                  <div class="photo-box">
                    <div class="good-bad">
                      <img
                        src="../../assets/images/Icon-Check.svg"
                        alt="Good Example"
                      />
                    </div>
                    <img
                      class="img-inside"
                      src="../../assets/images/photo_sub_2.jpg"
                      alt="Child working on a building kit zoomed in"
                    />
                  </div>

                  <div class="photo-box">
                    <div class="good-bad">
                      <img
                        src="../../assets/images/Icon-X.svg"
                        alt="Bad Example"
                      />
                    </div>
                    <img
                      class="img-inside"
                      src="../../assets/images/photo_sub_3.png"
                      alt="Child working on a building kit zoomed out to reveal clutter"
                    />
                  </div>
                </div>

                <div class="card-container even-cols gap-10 mb-60 max-w800">
                  <div class="photo-box">
                    <div class="good-bad">
                      <img
                        src="../../assets/images/Icon-Check.svg"
                        alt="Good Example"
                      />
                    </div>
                    <img
                      class="img-inside"
                      src="../../assets/images/photo_sub_4.png"
                      alt="Woman with VR headset on"
                    />
                  </div>

                  <div class="photo-box">
                    <div class="good-bad">
                      <img
                        src="../../assets/images/Icon-Check.svg"
                        alt="Good Example"
                      />
                    </div>
                    <img
                      class="img-inside"
                      src="../../assets/images/photo_sub_5.png"
                      alt="Two people talking in an office setting"
                    />
                  </div>

                  <div class="photo-box">
                    <div class="good-bad">
                      <img
                        src="../../assets/images/Icon-X.svg"
                        alt="Bad Example"
                      />
                    </div>
                    <img
                      class="img-inside"
                      src="../../assets/images/photo_sub_6.png"
                      alt="Many people clutter in an office setting"
                    />
                  </div>
                </div>

                <h3>Negative Space</h3>
                <p>
                  When choosing a photo, pay attention to negative space.
                  Negative space can be thought of as the surrounding area or
                  space between the focus or subject of an image.
                </p>
                <p>
                  This space helps the element stand out and attract attention.
                  Negative space is also helpful when incorporating text into
                  the photo without impeding the area of focus or
                  over-complicating the design.
                </p>

                <div class="container mt-40 max-w800">
                  <img
                    src="../../assets/images/astronaut.jpg"
                    alt="Example image of astronaut"
                    class="width-full"
                  />
                </div>
              </div>
            </section>

            <div class="divider"></div>

            <section id="file-types">
              <div class="content-band">
                <h2>Image File Types</h2>
                <p>
                  Choosing the right format for image files is key to optimizing
                  the load time of a websites or application.
                </p>
                <div class="p-40 gray-250 max-w800">
                  <div class="font-red semibold">
                    JPG / JPEG
                  </div>
                  <p>
                    The JPG/JPEG (Joint Photographic Experts Group) is a lossy
                    compressed file format that works best with photographs and
                    realistic images.
                  </p>
                  <div class="font-red semibold mt-20">
                    PNG
                  </div>
                  <p>
                    The Portable Network Graphic (PNG) is a lossless compressed
                    file format that works well for images requiring
                    transparency or containing text, line drawings, logos, and
                    icons. While photographs work well in PNG format, file size
                    can be extremely large and such usage is discouraged.
                  </p>
                  <div class="font-red semibold mt-20">
                    SVG
                  </div>
                  <p>
                    Scalable Vector Graphics (SVG) is a vector graphic file
                    format that offers small file sizes and the ability to scale
                    to any size without quality loss. SVGs enjoy wide browser
                    support but may not be supported by all content management
                    systems.
                  </p>
                  <div class="font-red semibold mt-20">
                    GIF
                  </div>
                  <p>
                    The GIF (Graphic Interchange Format) is an older, lossless
                    compressed file format that works best with text, line
                    drawings, and icons. GIFs use an indexed color palette of
                    256 colors and offer a small file size.
                  </p>
                </div>
              </div>
            </section>

            <div class="divider"></div>

            <section id="accessibility">
              <div class="content-band">
                <h2>Accessibility</h2>
                <p>
                  Alternative text, or alt text, must be provided for images
                  that aren’t considered decorative. Alt text is displayed if an
                  image doesn’t load and is read out to those using screen
                  readers.
                </p>
                <p>Good alt text:</p>
                <ul class="bullet-red">
                  <li>describes the content and function of the image</li>
                  <li>is meaningful and concise</li>
                  <li>
                    provides context/descriptions to search engine crawlers,
                    helping to improve SEO
                  </li>
                  <li>
                    if a link, conveys action initiated (e.g., destination).
                  </li>
                </ul>

                <div class="container gray-250 mt-40 p-40 max-w800">
                  <p class="mt-0">
                    Use the
                    <a
                      class="font-red"
                      href="https://www.w3.org/WAI/tutorials/images/decision-tree/"
                      alt="alt decision tree"
                      title="alt decision tree"
                      target="_new"
                      >alt decision tree
                    </a>
                    to determine if an image requires alt text to comply with
                    <a
                      class="font-red"
                      href="https://www.w3.org/TR/2008/REC-WCAG20-20081211/#text-equiv-all"
                      alt="WCAG 2.0 Success Criterion 1.1.1"
                      title="WCAG 2.0 Success Criterion 1.1.1"
                      target="_new"
                      >WCAG 2.0 Success Criterion 1.1.1 (Non-text Content) </a
                    >.
                  </p>
                </div>
              </div>
            </section>
          </div>

          <StickyTOC />

        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import StickyTOC from "../StickyTOC.vue";
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";

export default {
  name: "Photography",
  components: {
    TopLevelLeftNav,
    StickyTOC,
    SubFooter,
  },
};
</script>
